import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React, { useState, useContext, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Context from "../store/context";
import axios from "axios";
import { fontSize } from "@material-ui/system";

export default function ConfigBox(props) {
	//Variable to Store Global State
	const { state } = useContext(Context);
	const [role, setRole] = useState(sessionStorage.getItem("role"));

	//Array with status command to middleware
	const statusCommand = [
		"Standby",
		"Emergency",
		"Evacuation",
		"End_emergency",
		"Failure",
		"Falha"
	];

	//Hook for status of Meeting Point
	const [status, setStatus] = useState([]);
	//Hook to enable or disable Command List
	const [enable, setEnable] = useState(true);
	// Hook used for setTimeOut
	const [fakeCurrentDate, setFakeCurrentDate] = useState(new Date());
	// Hook to store value on the Radio
	const [value, setValue] = useState([]);
	//Hook to store date of last update
	const [date, setDate] = useState("");

	useEffect(() => {
		console.log("Submit All was updated!");
		checkClick();
		/* orderEqualsDatabaseOrder(); */
	}, [props.submitAllUpdated]);

	/*  useEffect(() => {
		   console.log(props.mp, status, '- Has changed')
	   },[status]) // <-- here put the parameter to listen */

	//Hook to update the selectCommands object for the SubmitAll component
	useEffect(() => {
		props.onChange({
			...props.selectedCommands,
			[props.mp]: value,
		});
	}, [value]);

	//FetchData on the page is loaded
	//If you want to run an effect and clean it up only once (on mount and unmount), you can pass an empty array ([]) as a second argument
	useEffect(() => {

		firstFetchStatus(props.mp)
		dateOfLastUpdate(props.mp)

	}, [sessionStorage.getItem('update')]);
	
	//UseEffect method to check every 5 seconds the state of enable hook
	//The default behavior for effects is to fire the effect after every completed render. That way an effect is always recreated if one of its dependencies changes.
	useEffect(() => {
		setTimeout(() => setFakeCurrentDate(new Date()), 5000);

		//If enable's hook is false starts fetching data from Database
		if (!enable) {


			checkClick();
			//Enable's hook is true When the response from MiddleWare is the same of order given by operator
			/* if (status === value) { */
			//setValue(status);
			//setEnable(true);
			//Method to change from End Emergency to Standby
			if (status === statusCommand[3]) {
				/* setValue("Standby"); */
				setStatus('Standby');
				checkClick();
				/* } */
			}
		}
	}, [fakeCurrentDate]);

	//First fetch to check last command given to the middleware
	//First fetch store the last command on Value Hook.
	const firstFetchStatus = (mp_area) => {
		axios
			.post(
				"https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/read-mpstatus.php",
				{
					id: mp_area,
				}
			)
			.then((res) => {
				if (res.data.status[0].operation === "Emergency") {
					state.emergencyFlag = true;
				}
				setStatus(res.data.status[0].operation);
				/* setValue(res.data.status[0].operation); */
				console.log(
					"Operation: " + mp_area + "--" + res.data.status[0].operation
				);
			})
			.catch((err) => {
				console.log(err);
			});

		axios
			.post(
				"https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/read-mpoperation.php",
				{
					id: mp_area,
				}
			)
			.then((res) => {
				setValue(res.data.status[0].operation);
				console.log(
					"Operation: " + mp_area + "--" + res.data.status[0].operation
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Method to fecth data while we are waiting for response of middleware
	const secondFetchStatus = (mp_area) => {
		axios
			.post(
				"https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/read-mpstatus.php",
				{
					id: mp_area,
				}
			)
			.then((res) => {
				if (res.data.status[0].operation === "Emergency") {
					state.emergencyFlag = true;
				}
				setStatus(res.data.status[0].operation);
			})
			.catch((err) => {
				console.log(err);
			});
	};


	//Method to check last update
	const dateOfLastUpdate = (mp) => {
		axios
			.post(
				"https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/read-mpstatus-datatime.php",
				{
					id: mp,
				}
			)
			.then((res) => {
				setDate(res.data.status[0].datatime);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Method to post on Database the order given to MiddleWare
	//Updates table mp_operation
	const updateData = (inputCommand) => {
		console.log(props.mp)
		console.log(inputCommand)
		axios.post(
			"https://meetingpointaveiro.2bconnect.pt/teste/php/middleware/update-mpoperation.php",
			{
				id: props.mp,
				operation: inputCommand,
				order_given:  sessionStorage.getItem('user'),
			}
		);
	};

	//Method to send commands to Middleware
	//Updates table mp_operation
	const updateDB = () => {
		updateData(value);
	};

	//Method to set Value choosen on Radio
	const handleChange = (event) => {
		event.preventDefault();
		setValue(event.target.value);
	};

	//Method to display value choosen on Radio
	const handleClick = () => {
		updateDB();
		checkClick();
		/* orderEqualsDatabaseOrder(); */
	};

	//Condition to check if the order given is the same of the last order read from database
	//If it is different change the value of Enable's Hook to false
	//This code is repeated on SubmitAll.js: needs REFACTORING
	const orderEqualsDatabaseOrder = () => {
		if (status !== value) {
			setEnable(false);
		}
	};

	//Method to turn on or off the flag of Emergency
	const checkClick = () => {
		console.log("teste safe");
		if (value === statusCommand[1]) {
			state.emergencyFlag = true;
		}
		if (value === statusCommand[3]) {
			state.emergencyFlag = false;
		}
		if (value === statusCommand[0]) {
			state.emergencyFlag = false;
		}
	};

	const useStyles = makeStyles({
		//Card
		root: {
			marginTop: "2rem",
			border: "1px solid black",
			boxShadow: "4px 4px 2px black",
		},
		title: {
			textAlign: "center",
			fontWeight: "bold",
			fontSize: "1.5rem",
		},
		status: {
			color: status == statusCommand[4] ? "red" : status == statusCommand[5] ? "red" : "black",
			textAlign: "center",
		},
		hidden: {
			visibility: "hidden",
		},
		buttons: {
			marginTop: "1rem",
			display: "flex",
			justifyContent: "center",
			borderRadius: "15px",
		},
	});

	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent>
				{/*Display de name Meeting Point*/}
				<FormLabel component="legend" className={classes.title}>
					{props.number ? (
						<>
							{/* MP */} {props.number}
							<br />
						</>
					) : null}
					{props.name}
				</FormLabel>

				<RadioGroup
					aria-label="status"
					name={props.name}
					value={value}
					onChange={handleChange}
				>
					{/*Display the Status of Meeting Point*/}
					<p className={classes.status}>
						{status == statusCommand[0] ? "StandBy" : ""}
						{status == statusCommand[1] ? "Emergência" : ""}
						{status == statusCommand[2] ? "Evacuação" : ""}
						{status == statusCommand[3] ? "StandBy" : ""}
						{status == statusCommand[4] ? "Falha" : ""}
						{status == statusCommand[5] ? "Falha" : ""}
					</p>
					{/*Display the commands on Radio Group.
          if enable is true the commands are available if not the commands aren't available*/}
					{enable ? (
						<FormControlLabel
							value={statusCommand[0]}
							control={<Radio />}
							label="StandBy"
						/>
					) : (
						<FormControlLabel
							value={statusCommand[0]}
							control={<Radio />}
							label="StandBy"
							disabled
						/>
					)}
					{enable ? (
						<FormControlLabel
							value={statusCommand[1]}
							control={<Radio />}
							label="Emergência"
						/>
					) : (
						<FormControlLabel
							value={statusCommand[1]}
							control={<Radio />}
							label="Emergência"
							disabled
						/>
					)}
					{enable ? (
						<FormControlLabel
							value={statusCommand[2]}
							control={<Radio />}
							label="Evacuação"
						/>
					) : (
						<FormControlLabel
							value={statusCommand[2]}
							control={<Radio />}
							label="Evacuação"
							disabled
						/>
					)}
					{props.disableEndEmergencyRadio ? (
						<FormControlLabel
							value={statusCommand[3]}
							control={<Radio />}
							label="Fim Emergência"
							className={classes.hidden}
						/>
					) : enable ? (
						<FormControlLabel
							value={statusCommand[3]}
							control={<Radio />}
							label="Fim Emergência"
						/>
					) : (
						<FormControlLabel
							value={statusCommand[3]}
							control={<Radio />}
							label="Fim Emergência"
							disabled
						/>
					)}

					<span>Last update: </span>
					<span>{date}</span>
				</RadioGroup>

				{enable && role == "admin" ? (
					<div className={classes.buttons}>
						<Button
							variant="contained"
							size="small"
							color="primary"
							onClick={() => {
								handleClick();
							}}
						>
							Submeter
						</Button>
					</div>
				) : (
					<Button
						variant="contained"
						size="small"
						disabled
						onClick={() => {
							handleClick();
						}}
					>
						Submeter
					</Button>
				)}
			</CardContent>
		</Card>
	);
}
