import { BrowserRouter as Router, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import AddUserDialog from "./AddUserDialog";
import RemoveUserDialog from './RemoveUserDialog';
import EditUserDialog from './EditUserDialog';
import EditMPDialog from './EditMPDialog';
import AddAlertDialog from "./AddAlertDialog";
import RemoveAlertDialog from './RemoveAlertDialog';
import EditAlertDialog from './EditAlertDialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import axios from "axios";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    '@media (max-width: 2500px)': {
      maxWidth: '50vw',
    },
    '@media (max-width: 1250px)': {
      maxWidth: '65vw',
    },
    '@media (max-width: 1000px)': {
      maxWidth: '85vw',
    },
    '@media (max-width: 539px)': {
      maxWidth: '100vw',
    }
  },
});

const useStyles1 = makeStyles(() => ({
  buttons: {
    marginBottom: '15px',
    backgroundColor: 'LawnGreen',
    '&:hover': {
      backgroundColor: 'lime'
    }
  },
  selectRole: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  warning: {
    color: 'red',
    fontWeight: 'bold',
  }
}));



/* const drawerWidth = {
  width: '50%',
  '@media (min-width: 780px)' : {
    width: '80%'
  }
} */

export default function UsersTable(props) {
  const classes = useStyles();
  const classes1 = useStyles1();

  return (

    <TableContainer className={classes.container} component={Paper}>
      <h4 className={classes.title} style={{ textAlign: "center" }}>GESTÃO DOS UTILIZADORES</h4>
      <br></br>
      {<AddUserDialog fetchUsersData={props.fetchUsersData} />}
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Nome</StyledTableCell>
            <StyledTableCell align="center">Utilizador</StyledTableCell>
            <StyledTableCell align="center">Role</StyledTableCell>
            <StyledTableCell align="center">Acção</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.usersData.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.user}</StyledTableCell>
              <StyledTableCell align="center">{row.role}</StyledTableCell>
              <StyledTableCell align="center">
                <RemoveUserDialog user={row.user} fetchUsersData={props.fetchUsersData} />
                <EditUserDialog id={row.id} name={row.name} user={row.user} role={row.role} fetchUsersData={props.fetchUsersData} usersData={props.usersData} /* h1={props.h1} h2={props.h2} h3={props.h3} */ />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <br></br>
      <br></br>
      <br></br>
      <h4 className={classes.title} style={{ textAlign: "center" }}>GESTÃO DE ALERTAS</h4>
      <br></br>
      <AddAlertDialog fetchAlertsData={props.fetchAlertsData} />
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Data</StyledTableCell>
            <StyledTableCell align="center">Nome</StyledTableCell>
            <StyledTableCell align="center">Email</StyledTableCell>
            <StyledTableCell align="center">Alertas</StyledTableCell>
            <StyledTableCell align="center">Acção</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.alertsData ? props.alertsData.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.datatime}
              </StyledTableCell>
              <StyledTableCell align="center">{row.name}</StyledTableCell>
              <StyledTableCell align="center">{row.email}</StyledTableCell>
              <StyledTableCell align="center">{row.alert}</StyledTableCell>
              <StyledTableCell align="center">
                <RemoveAlertDialog name={row.name} fetchAlertsData={props.fetchAlertsData} />
                <EditAlertDialog id={row.input} datatime={row.datatime} name={row.name} email={row.email} alert={row.alert} fetchAlertsData={props.fetchAlertsData} alertsData={props.alertsData} />
              </StyledTableCell>
            </StyledTableRow>
          )) : <StyledTableRow>
            <StyledTableCell align="center" component="th" scope="row">
              Sem Resultados
            </StyledTableCell>
            <StyledTableCell align="center">Sem Resultados</StyledTableCell>
            <StyledTableCell align="center">Sem Resultados</StyledTableCell>
            <StyledTableCell align="center">Sem Resultados</StyledTableCell>
            <StyledTableCell align="center">Sem Resultados</StyledTableCell>
          </StyledTableRow>
          }
        </TableBody>
      </Table>
      <br></br>
      <br></br>
      <br></br>
      <div  style={{ textAlign: "center" }}>
      <h4 className={classes.title} style={{ textAlign: "center" }}>GESTÃO DO SOM</h4>
      <Link to="/sound" style={{ textAlign: "center" }}>
        <Button variant="contained" className={classes1.buttons} style={{ textAlign: "center" }}>Configuração</Button>
      </Link>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <h4 className={classes.title} style={{ textAlign: "center" }}>GESTÃO MEETING POINTS</h4>
      <br></br>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">MP</StyledTableCell>
            <StyledTableCell align="center">Nome</StyledTableCell>
            <StyledTableCell align="center">Acção</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.MPData.map((row) => (
            <StyledTableRow >
              <StyledTableCell align="center">{row.MP_ID}</StyledTableCell>
              <StyledTableCell align="center">{row.name}</StyledTableCell>
              <StyledTableCell align="center">
                <EditMPDialog id={row.MP_ID} name={row.name} fetchMPData={props.fetchMPData} MPData={props.MPData} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <br></br>
      <br></br>
      <br></br>
      <h4 className={classes.title} style={{ textAlign: "center" }}>REGISTO DE OPERAÇÕES</h4>
      <br></br>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Data</StyledTableCell>
            <StyledTableCell align="center">Utilizador</StyledTableCell>
            <StyledTableCell align="center">ID</StyledTableCell>
            <StyledTableCell align="center">MP</StyledTableCell>
            <StyledTableCell align="center">Operação</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.operationsData ? props.operationsData.map((row) => (
            <StyledTableRow key={row.user}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.datatime}
              </StyledTableCell>
              <StyledTableCell align="center">{row.user}</StyledTableCell>
              <StyledTableCell align="center">{row.id}</StyledTableCell>
              <StyledTableCell align="center">{row.id == 3 ? 'MP_01' : row.id == 4 ? 'MP_02' : row.id == 5 ? 'MP_03' : row.id == 6 ? 'MP_04' : row.id == 7 ? 'MP_05' : row.id == 8 ? 'EP_01' : row.id == 9 ? 'GVIR_01' : row.id == 10 ? 'GW_01' : row.id == 11 ? 'WS_01' : row.id == 12 ? 'MP_01' : null}</StyledTableCell>
              <StyledTableCell align="center">{row.operation}</StyledTableCell>
            </StyledTableRow>
          )) : <StyledTableRow>
            <StyledTableCell align="center" component="th" scope="row">
              Sem Resultados
            </StyledTableCell>
            <StyledTableCell align="center">Sem Resultados</StyledTableCell>
            <StyledTableCell align="center">Sem Resultados</StyledTableCell>
            <StyledTableCell align="center">Sem Resultados</StyledTableCell>
            <StyledTableCell align="center">Sem Resultados</StyledTableCell>
          </StyledTableRow>
          }
        </TableBody>
      </Table>
      <br></br>
      <br></br>
      <br></br>
    </TableContainer>
  );
}