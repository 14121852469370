import React from 'react';
import { GiWindsock, GiSteamBlast } from 'react-icons/gi';
import { RiAdminLine, RiLogoutCircleRLine } from 'react-icons/ri';
import { AiOutlineSound } from 'react-icons/ai';
import '../styles/NavBar.css';

//Options of our SideBar are stored in a Array of Objects
const TopBarIcons = [
	/* {
		title: 'SOM',
		path: '/sound',
		icon: <AiOutlineSound />,
		cName: 'nav-text',
	}, */
	{
		title: 'GVIR',
		path: '/gvir',
		icon: <GiSteamBlast />,
		cName: 'nav-text',
	},
	{
		title: 'WIND',
		path: '/wind',
		icon: <GiWindsock />,
		cName: 'nav-text',
	},
	{
		title: 'ADMIN',
		path: '/admin',
		icon: <RiAdminLine />,
		cName: 'nav-text',
	},

	{
		title: 'LOGOUT',
		path: '/login',
		icon: <RiLogoutCircleRLine />,
		cName: 'nav-text',
	},
];

export default TopBarIcons;
